import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

interface PageProps {
  className?: string
}

const Content: React.FC<PageProps> = ({ children }) => (
  <Container as="section" className="mt-3 mb-3">
    <Row>
      <Col lg={12}>{children}</Col>
    </Row>
  </Container>
)

export default Content
